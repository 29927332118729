import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetAdminLayoutDataQueryHookResult,
  useGetAdminLayoutDataQuery,
} from "../../graphqGenaretedTypes"

export type ClientAdminLayoutContextData = {
  organisationPrivacyNotice: boolean
  customPrivacyNoticeUrl?: string
  currentUserId?: number
  loading: boolean
  reporter: boolean
  backendUser: boolean
  canEditBenefits: boolean
  isOrganisationSettingUser: boolean
  commsHubLink?: string
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
}

const defaultValue: ClientAdminLayoutContextData = {
  organisationPrivacyNotice: false,
  customPrivacyNoticeUrl: "",
  currentUserId: undefined,
  loading: true,
  backendUser: false,
  reporter: false,
  employeeOrganisations: [],
  isOrganisationSettingUser: false,
  canEditBenefits: false,
}

const valueFromQueryResult = function (
  result: GetAdminLayoutDataQueryHookResult
): ClientAdminLayoutContextData {
  const data = result.data
  const loading = result.loading

  return {
    customPrivacyNoticeUrl:
      data?.employeeOrganisation?.customPrivacyNoticeUrl ?? undefined,
    organisationPrivacyNotice:
      data?.employeeOrganisation?.organisationPrivacyNotice || false,
    reporter: data?.employeeOrganisation?.reporter || false,
    currentUserId: data?.currentUser?.id,
    backendUser: data?.currentUser?.backendUser || false,
    isOrganisationSettingUser:
      data?.currentUser?.isOrganisationSettingUser || false,
    employeeOrganisations: data?.currentUser?.employeeOrganisations || [],
    commsHubLink: data?.employerOrganisation?.commsHubLink || undefined,
    canEditBenefits:
      data?.currentUser?.rolesForOrganisation?.includes("organisation_admin") ||
      data?.currentUser?.backendUser ||
      false,

    loading: loading,
  }
}

export const useClientAdminLayoutData =
  function (): ClientAdminLayoutContextData {
    const params = useParams()
    const userData = useGetAdminLayoutDataQuery({
      variables: { organisationId: params.organisationId ?? "" },
      errorPolicy: "all",
    })

    return valueFromQueryResult(userData)
  }

export const ClientAdminLayoutContext = createContext(defaultValue)
