import { css, styled } from "@mui/material"
import { FamilyPay as FamilyPayType } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"
import { Header } from "./Header"
import { Button } from "./Button"
import i18n from "../../../../i18n"
import {
  FamilyPayOuterGrid,
  PaddingContentGrid,
  FamilyCareLogo,
  FamilyCareLogos as FamilyPayLogos,
  SupportType,
  Icon,
  IconText,
  MORE_BUTTON_COLOR,
} from "../../shared/family-care-and-family-pay/ContentStyles"
import { Button as MuiButton } from "@mui/material"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const getLogosData = () => [
  {
    label: i18n.t("portalHomepage:familyPay.services.nursery"),
    img: "globalMegaMenu/Nursery.png",
  },
  {
    label: i18n.t("portalHomepage:familyPay.services.childminder"),
    img: "globalMegaMenu/Childminder.png",
  },
  {
    label: i18n.t("portalHomepage:familyPay.services.holidayClubs"),
    img: "globalMegaMenu/HolidayClubs.png",
  },
]

export function Content({ data }: { data: FamilyPayType | undefined }) {
  const isMobile = useIsMobileView()
  const usefulLinks = data?.usefulLinks
  const familyPayLogo = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/FamilyPayIconImage.png"
  )

  return (
    <>
      <Header link={data?.link || "#"} />
      <FamilyPayOuterGrid>
        <PaddingContentGrid>
          <FamilyPayLogo src={familyPayLogo} alt="family pay logo" />
          <FamilyPayLogos>
            {usefulLinks &&
              usefulLinks.map(({ label, link }) => {
                const logosData = getLogosData()
                const logo = logosData.find((data) => data.label == label)
                if (logo) {
                  return (
                    <MuiButton href={link} target="_blank" rel="noopener">
                      <SupportType key={`family-pay-${label}`}>
                        <Icon
                          // eslint-disable-next-line react-hooks/rules-of-hooks
                          src={useBuildAwsImageUrl(i18n.language, logo.img)}
                          alt={label}
                        />
                        <Text>{label}</Text>
                      </SupportType>
                    </MuiButton>
                  )
                }
              })}
          </FamilyPayLogos>
          {isMobile && <Button link={data?.link || "#"} />}
        </PaddingContentGrid>
      </FamilyPayOuterGrid>
      {isMobile && usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor={MORE_BUTTON_COLOR}
          isExternalLink
        />
      )}
    </>
  )
}

const Text = styled(IconText)`
  ${({ theme }) => css`
    min-width: 80px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      min-width: 95px;
    }
  `}
`

const FamilyPayLogo = styled(FamilyCareLogo)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 11px;
    }
  `}
`
