import { useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import CondensedLogosection from "./condensed_header/Condensed_LogoSection"
import DefaultLogosection from "./default_header/Default_LogoSection"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"
import i18n from "../../../../i18n"

export type LogoSectionType = {
  logo?: string
  condensedLogo?: string
  organisationName?: string
  isSingleBenefit?: boolean
  invalidSingleBenefitScheme?: boolean
}

const LogoSection = ({
  logo,
  condensedLogo,
  organisationName,
  isSingleBenefit,
  invalidSingleBenefitScheme,
}: LogoSectionType) => {
  const [isShrunk, setShrunk] = useState(false)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down("md"))
  const defaultLogo = useBuildAwsImageUrl(i18n.language, "logovivup.png")
  const defaultCondensedLogo = useBuildAwsImageUrl(
    i18n.language,
    "smallLogoVivup.png"
  )

  logo = logo ?? defaultLogo
  condensedLogo = condensedLogo ?? defaultCondensedLogo

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50)
        ) {
          return true
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false
        }

        return isShrunk
      })
    }

    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  return (
    <>
      {(!isDesktop && isShrunk) || isDesktop ? (
        <CondensedLogosection
          logo={condensedLogo}
          organisationName={organisationName}
          isSingleBenefit={isSingleBenefit}
          invalidSingleBenefitScheme={invalidSingleBenefitScheme}
        />
      ) : (
        <DefaultLogosection
          logo={logo}
          organisationName={organisationName}
          isSingleBenefit={isSingleBenefit}
          invalidSingleBenefitScheme={invalidSingleBenefitScheme}
        />
      )}
    </>
  )
}

export default LogoSection
