import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { Box, Button, css, styled, Typography } from "@mui/material"
import { HealthAndWellbeingButton } from "../global-mega-menu/HealthAndWellbeingDesktopCard"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

export type SlideProps = {
  name?: string
  customLink: string
  handleLink: (customLink: string, external: boolean) => void
}
export type SlideCardProps = {
  name?: string
  customLink: string
  handleLink: (customLink: string, external: boolean) => void
  items: {
    label: string
    link?: string
  }[]
}
const FamilyCareNameHeading = ({
  name,
  customLink,
  handleLink,
}: SlideProps) => {
  const { t } = useTranslation("portalHomepage")
  return (
    <div>
      {name === "familyCare" ? (
        <TittleLink
          className="mega-menu-tab__category mega-menu-tab__title"
          style={{
            paddingLeft: "0px",
            fontWeight: "bold",
          }}
          onClick={() => {
            handleLink(customLink, true)
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#302557",
              fontWeight: "bold",
            }}
          >
            {t("familyCare.familyCareHeading")}
          </div>
          <ArrowForwardIcon
            fontSize="inherit"
            className="mega-menu-tab__forward-arrow"
            sx={{
              color: "#35185f",
            }}
          />
        </TittleLink>
      ) : null}
    </div>
  )
}
const FamilyCareMobileCard = ({
  customLink,
  handleLink,
  items,
}: SlideCardProps) => {
  const { t, i18n } = useTranslation("portalHomepage")
  const childcare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Childcare.png"
  )
  const eldercare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Eldercare.png"
  )
  const petcare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Petcare.png"
  )
  const familyCareIconImage = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/FamilyCareIconImage.png"
  )

  return (
    <div style={{ paddingTop: "3rem" }}>
      <div style={{ textAlign: "center" }}>
        <img
          src={familyCareIconImage}
          alt="FamilyCareIconImage"
          style={{
            paddingBottom: "1rem",
            paddingTop: "22px",
            width: "152px",
            height: "55px",
          }}
        />
        <Cardlayout style={{ textAlign: "center" }}>
          {items.some((item) => item.label === "Childcare Support") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Childcare Support")
                    ?.link || "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyCareIcon src={childcare} alt="ChildcareImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.childcare")}
              </TextContainer>
            </Button>
          )}

          {items.some((item) => item.label === "Eldercare Support") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Eldercare Support")
                    ?.link || "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyCareIcon
                  src={eldercare}
                  alt="EldercareImage"
                  sx={{
                    width: "21.79px !important",
                    height: "28.46px !important",
                  }}
                />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.eldercare")}
              </TextContainer>
            </Button>
          )}
          {items.some((item) => item.label === "Petcare Support") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Petcare Support")
                    ?.link || "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyCareIcon src={petcare} alt="PetCareImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.petcare")}
              </TextContainer>
            </Button>
          )}
        </Cardlayout>
        <ExploreButton onClick={() => handleLink(customLink, true)}>
          {t("familyCare.buttonText")}
        </ExploreButton>
      </div>
    </div>
  )
}
export { FamilyCareNameHeading, FamilyCareMobileCard }
const TittleLink = styled("div")`
  &:hover {
    background-color: transparent;
  }
`

const ExploreButton = styled(HealthAndWellbeingButton)`
  &&&& {
    max-width: 283.15px;
    width: 100%;
    background-color: #6a6ba8;
    margin-top: 2rem;
  }
`
export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
  }
`
export const ContentContainerHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;

    font-size: 15px;
    line-height: 22px;
    margin-bottom: 4px;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 14 px;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 21px;
    }
  `}
`

export const ImageConatiner = styled(Box)`
  ${({ theme }) => css`
    max-width: 54.26px;
    width: 100%;
    height: 54.26px;
    background-color: white;
    opacity: 1;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 54.26px;
      max-width: 54.26px;
      width: 100%;
    }
  `}
`
export const FamilyCareIcon = styled("img")`
  ${({ theme }) => css`
    width: 31.13px;
    height: 25.35px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 31.13px;
      height: 25.35px;
    }
  `}
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      max-width: 100%;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 12px;
    margin-top: 8px; // Adjust the margin as needed
    font-weight: 700;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 4px; // Adjust the margin as needed
    }
  `}
`
