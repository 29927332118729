import {
  usePortalHomepageData,
  PortalHomepageContext,
} from "./PortalHomepageContext"
import Loader from "../shared/Loader"
import ScrollToTop from "../shared/ScrollToTop"
import WaveContainer from "../shared/wave-container/WaveContainer"
import HomeAndElectronicsSection from "./home-and-electronics-section/HomeAndElectronicsSection"
import HighFiveBanner from "../highfive-banner/HighFiveBanner"
import C2W from "./dynamic-propositions/cycle-to-work/C2W"
import CarBenefit from "./dynamic-propositions/car-benefit/CarBenefit"
import HealthAndWellbeing from "./dynamic-propositions/health-and-wellbeing/HealthAndWellbeing"
import LifestyleSavings from "./dynamic-propositions/lifestyle-savings/LifestyleSavings"
import { WidgetWrapper } from "./dynamic-propositions/WidgetWrapper"
import BenefitCardCarouselGrid from "../explore-your-benefits/BenefitCardCarouselGrid"
import PortalHomePageBanner from "./PortalHomePageBanner"
import TrustPilot from "./homepage-trust-pilot/TrustPilot"
import { CommentsImages } from "./homepage-trust-pilot/CommentsImages"
import NoticeBoard from "./notice-board/NoticeBoard"
import BannerSpace from "./BannerSpace"
import { Fragment, useRef } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useEffect } from "react"
import {
  gtmOnPageland,
  gtmPortalHomepageLanding,
} from "../shared/gtm-events/CustomGtmEvents"
import { useTheme } from "@mui/material/styles"
import { groupInput } from "./groupInput"
import { Box, styled } from "@mui/material"
import { DynamicPropositionSpace1 } from "./dynamic-propositions/DynamicPropositionSpace1"
import GymWidget from "./gym-widget/GymWidget"
import FamilyCare from "./dynamic-propositions/family-care/FamilyCare"
import FamilyPay from "./dynamic-propositions/family-pay/FamilyPay"

export default function PortalHomepage() {
  const { data } = usePortalHomepageData()

  const params = useParams()
  const organisationId = params.organisationId
  const theme = useTheme()
  const groups = groupInput(data.areas)
  const store = "PoratalHomepage"
  const location = useLocation()
  const lastHash = useRef("")

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" })
        lastHash.current = ""
      }, 100)
    }
  }, [data.loading, location.hash])

  const backgroundColorMapping: { [key: string]: string } = {
    gray: theme.palette.grey[200],
    white: theme.palette.white.main,
    blue: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
  }

  const componentMapping: { [key: string]: JSX.Element } = {
    family_care: <FamilyCare data={data.familyCare} />,
    home_and_electronics: (
      <HomeAndElectronicsSection roundels={data.heRoundels} />
    ),
    explore_your_benefits: (
      <BenefitCardCarouselGrid benefitCardData={data.payrollPayBenefits} />
    ),
    highfive_banner: (
      <HighFiveBanner
        show={data.areas.includes("highfive_banner") && data?.banner.hi5}
        link={data.hi5Url}
      />
    ),
    health_and_wellbeing: <HealthAndWellbeing data={data.healthAndWellbeing} />,
    lifestyle_savings: <LifestyleSavings categories={data.lssCategories} />,
    employee_noticeboard: <NoticeBoard input={data.noticeboards} />,
    banner_space: <BannerSpace />,
    trustpilot: <TrustPilot Comments={CommentsImages} />,
    gym_widget: <GymWidget gymUrl={data.gymUrl} />,
    family_pay: <FamilyPay data={data.familyPay} />,
  }

  useEffect(() => {
    if (!data.loading) gtmPortalHomepageLanding(organisationId)
    gtmOnPageland(store)
  })

  return (
    <PortalHomepageContext.Provider value={data}>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          <PortalHomePageBanner
            banners={data.banner}
            hifiveBanner={data.banner.hi5}
            show={true}
            hi5SsoUrl={data.hi5SsoUrl}
          />
          {groups.map(({ categories, color }, groupIndex) => {
            const lastGroupColor =
              groupIndex - 1 >= 0 ? groups[groupIndex - 1].color : undefined
            const nextGroupColor =
              groupIndex + 1 < groups.length
                ? groups[groupIndex + 1].color
                : undefined

            const lastElement = groupIndex === groups.length - 1
            const isOddElement = groupIndex % 2 === 1
            const background = backgroundColorMapping[color]

            function getShowBottomWave() {
              if (lastElement || nextGroupColor == "blue") return false
              if (color === "blue") return true
              return isOddElement
            }

            const showBottomWave = getShowBottomWave()
            const bottomWaveColour =
              nextGroupColor && backgroundColorMapping[nextGroupColor]

            function getShowTopWave() {
              if (lastGroupColor == "blue") return false
              if (color === "blue") return true
              return isOddElement
            }

            const showTopWave = getShowTopWave()
            const topWaveColour =
              lastGroupColor && backgroundColorMapping[lastGroupColor]

            return (
              <WaveContainer
                background={background}
                showBottomWave={showBottomWave}
                bottomWaveColour={bottomWaveColour}
                showTopWave={showTopWave}
                topWaveColour={topWaveColour}
                key={groupIndex}
              >
                {categories.includes("car_benefit") ||
                categories.includes("cycle_to_work") ? (
                  <SpacingWrapper>
                    {categories.map((category, i) => {
                      const pairIndices = getConsecutiveIndices(categories)
                      // If this element and the next one form a pair, show in columns
                      if (
                        pairIndices.includes(i) &&
                        pairIndices.includes(i + 1)
                      ) {
                        const nextCategory = categories[i + 1]
                        return (
                          <DynamicPropositionSpace1 inColumns key={i}>
                            <>
                              {category === "cycle_to_work" ? (
                                <C2W
                                  roundels={data.c2wRoundels}
                                  onlyC2W={false}
                                  cycleToWorkStore={true}
                                />
                              ) : (
                                <CarBenefit onlyCarBenefit={false} />
                              )}
                              {nextCategory === "cycle_to_work" ? (
                                <C2W
                                  roundels={data.c2wRoundels}
                                  onlyC2W={false}
                                  cycleToWorkStore={true}
                                />
                              ) : (
                                <CarBenefit onlyCarBenefit={false} />
                              )}
                            </>
                          </DynamicPropositionSpace1>
                        )
                      }

                      // If this element was part of a pair, it's already been rendered above
                      if (
                        pairIndices.includes(i) &&
                        pairIndices.includes(i - 1)
                      ) {
                        return null
                      }

                      // If the component is part of the pair but it's not consecutive
                      if (
                        category === "car_benefit" ||
                        category === "cycle_to_work"
                      ) {
                        return (
                          <DynamicPropositionSpace1 key={i}>
                            {category === "cycle_to_work" ? (
                              <C2W
                                roundels={data.c2wRoundels}
                                onlyC2W={true}
                                cycleToWorkStore={true}
                              />
                            ) : (
                              <CarBenefit onlyCarBenefit={true} />
                            )}
                          </DynamicPropositionSpace1>
                        )
                      }

                      // If the component isn't part of the pair at all, just render it
                      return <>{componentMapping[category]}</>
                    })}
                  </SpacingWrapper>
                ) : categories.includes("lifestyle_savings") ||
                  categories.includes("health_and_wellbeing") ||
                  categories.includes("family_care") ||
                  categories.includes("family_pay") ? (
                  <WidgetWrapper>
                    <>
                      {categories.map((category, i) => {
                        return (
                          <Fragment key={i}>
                            {componentMapping[category]}
                          </Fragment>
                        )
                      })}
                    </>
                  </WidgetWrapper>
                ) : (
                  <SpacingWrapper>
                    {categories.map((category, i) => {
                      return (
                        <Fragment key={i}>
                          {componentMapping[category]}
                        </Fragment>
                      )
                    })}
                  </SpacingWrapper>
                )}
              </WaveContainer>
            )
          })}
          <ScrollToTop showBelow={500} />
        </>
      )}
    </PortalHomepageContext.Provider>
  )
}

const SpacingWrapper = styled(Box)`
  & > div:first-of-type {
    margin-top: 0;
  }
  & > div {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`

function getConsecutiveIndices(input: string[]): number[] {
  const pair = ["cycle_to_work", "car_benefit"]
  const indices = []

  for (let i = 0; i < input.length - 1; i++) {
    if (
      (input[i] === pair[0] && input[i + 1] === pair[1]) ||
      (input[i] === pair[1] && input[i + 1] === pair[0])
    ) {
      indices.push(i, i + 1)
    }
  }

  return indices
}
