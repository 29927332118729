import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetCurrentUserDataQuery } from "../../graphqGenaretedTypes"
import { gtmRequestCallBackClick } from "../shared/gtm-events/CustomGtmEvents"

type RequestCallBackProps = {
  // firstName: string
  // lastName: string
  // email: string
  // mobileNumber: string
  schemeType: string
  number: number
}
const RequestCallBack = ({
  // firstName,
  // lastName,
  // email,
  // mobileNumber,
  schemeType,
  number,
}: RequestCallBackProps) => {
  const theme = useTheme()
  const params = useParams()
  // TODO: use the data from context
  const userData = useGetCurrentUserDataQuery()
  const { t, ready } = useTranslation("clientAdmin")
  const firstName = userData.data?.currentUser?.firstName || ""
  const lastName = userData.data?.currentUser?.lastName || ""
  const email = userData.data?.currentUser?.email || ""
  const mobileNumber = userData.data?.currentUser?.mobileNumber || ""
  const mailTo = `mailto:sales@vivup.co.uk?subject=Request%20a%20call%20back&body=Hi,%0A%0ASeveral%20of%20our%20employees%20have%20requested%20for%20the%20${schemeType}%20benefit%20to%20be%20opened.%20Could%20someone%20from%20Vivup%20please%20contact%20me%20to%20discuss%20my%20options%3F%0A%0ARegards,%0A%0A${firstName}%20${lastName}%0A${
    params.organisationId
  }%0A${email}${mobileNumber ? "," : ""}%20${mobileNumber}`
  const event = "request_a_call_back"

  if (!ready) {
    return null
  }
  return (
    <div>
      <p>
        <a
          href={mailTo}
          style={{ color: theme.palette.secondary.main }}
          target="_top"
          onClick={() => {
            gtmRequestCallBackClick(event)
          }}
        >
          <span className="screen-reader-only">
            {number.toString() +
              " " +
              t("clientAdmin:benefitManagement.requestsToOpen.card.text") +
              " " +
              schemeType}
          </span>
          {t("clientAdmin:benefitManagement.requestsToOpen.card.button")} {">"}
        </a>
      </p>
    </div>
  )
}
export default RequestCallBack
