import { FamilyCare as FamilyCareType } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"
import { Header } from "./Header"
import { Button } from "./Button"
import i18n from "../../../../i18n"
import {
  PaddingOuterGrid,
  PaddingContentGrid,
  FamilyCareLogo,
  FamilyCareLogos,
  SupportType,
  Icon,
  IconText,
  MORE_BUTTON_COLOR,
} from "../../shared/family-care-and-family-pay/ContentStyles"
import { Button as MuiButton } from "@mui/material"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const getLogosData = () => [
  {
    label: i18n.t("portalHomepage:familyCare.services.childcare"),
    img: "globalMegaMenu/Childcare.png",
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.eldercare"),
    img: "globalMegaMenu/Eldercare.png",
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.petcare"),
    img: "globalMegaMenu/Petcare.png",
  },
]

export function Content({ data }: { data: FamilyCareType | undefined }) {
  const isMobile = useIsMobileView()
  const usefulLinks = data?.usefulLinks

  const familyCareLogo = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/FamilyPayIconImage.png"
  )

  return (
    <>
      <Header />
      <PaddingOuterGrid>
        <PaddingContentGrid>
          <FamilyCareLogo src={familyCareLogo} alt="family care logo" />
          <FamilyCareLogos>
            {usefulLinks &&
              usefulLinks.map(({ label, link }) => {
                const logosData = getLogosData()
                const logo = logosData.find((data) => data.label == label)
                if (logo) {
                  return (
                    <MuiButton href={link}>
                      <SupportType key={`family-care-${label}`}>
                        <Icon
                          // eslint-disable-next-line react-hooks/rules-of-hooks
                          src={useBuildAwsImageUrl(i18n.language, logo.img)}
                          alt={label}
                        />
                        <IconText>{label}</IconText>
                      </SupportType>
                    </MuiButton>
                  )
                }
              })}
          </FamilyCareLogos>
          {isMobile && <Button />}
        </PaddingContentGrid>
      </PaddingOuterGrid>
      {isMobile && usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor={MORE_BUTTON_COLOR}
        />
      )}
    </>
  )
}
