import { InputAdornment, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import useIsMobileView from "../../utils/useIsMobileView"
import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../registration/sign-up/SignUp"
import GenericField from "../shared/form/GenericField"
import InfoTooltip from "../shared/InfoTooltip"
import { getBenefitTitleField } from "./fields"
import { NewBenefitTileValue } from "./formikUtils"
import { FormikValues } from "formik"
export function EnterBenefitTileField({
  translation,
}: {
  translation: string
}) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation(translation)
  const textFieldProps = getBenefitTitleField(translation)
  const formik = useFormikContext<NewBenefitTileValue>()
  const [characterCount, setCharacterCount] = useState(0)
  const maxCharacters = 20
  const theme = useTheme()
  const handleInputChange = (formik: FormikValues) => {
    const count = formik.values.BenefitTitle?.length ?? 0
    setCharacterCount(count)
  }

  useEffect(() => {
    handleInputChange(formik)
    // eslint-disable-next-line
  }, [formik.values.BenefitTitle])

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip>
      <GenericField
        textFieldProps={{
          ...textFieldProps,
          onChange: handleInputChange,
        }}
        sx={
          isMobileView
            ? {
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
                marginBottom: 0,
              }
            : { marginBottom: 0 }
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                label: textFieldProps.label,
                inputProps: {
                  maxLength: maxCharacters,
                },
                required: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={t("enterBenefitTileTooltip")} />
                    </InputAdornment>
                  ),
                },
              }
            : {
                label: textFieldProps.label,
                required: true,
                inputProps: {
                  maxLength: maxCharacters,
                },
              }
        }
      />
      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t("enterBenefitTileTooltip")} />
        </ToolTipContainer>
      )}
      <div style={{ color: theme.palette.grey[700], float: "right" }}>
        {characterCount} / {maxCharacters}
      </div>
    </FieldWithTooltip>
  )
}
